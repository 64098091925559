<template>
  <!-- TODO: Đưa props vào store state -->
  <CandidateConfirm targetTest="apps-academic-reading-instruction"></CandidateConfirm>
</template>

<script>
import CandidateConfirm from "@/views/components/CandidateConfirm";

export default {
  name: "ConfirmInformation",
  components: {CandidateConfirm}
}
</script>

<style scoped>
</style>
